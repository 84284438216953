<template>
  <div>
    <b-card no-body>
      <proforma-summary />
    </b-card>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Ürünler</b-card-title>
      </b-card-header>
      <products />
      <template v-if="userData.id_dealers">
        <template v-if="userData.id_dealers === dataItem.id_dealers">
          <b-card-footer>
            <div class="mt-1 text-center d-flex justify-content-between">
              <div class="text-left">
                <div class="d-inline">
                  <b-button
                    variant="warning"
                    class="mr-2"
                    :to="'/app/proforma-invoices/edit/' + dataItem.id"
                  >
                    <FeatherIcon icon="EditIcon" />
                    Güncelle
                  </b-button>
                </div>
              </div>
              <div class="text-right">
                <div class="d-inline">
                  <b-button
                    :href="downloadUrl"
                    target="_blank"
                    variant="success"
                  >
                    <FeatherIcon icon="PrinterIcon" />
                    Yazdır
                  </b-button>
                </div>
                <!--            <div class="d-inline">-->
                <!--              <b-button-->
                <!--                variant="danger"-->
                <!--                class="ml-1"-->
                <!--                @click="toggleMailForm"-->
                <!--              >-->
                <!--                <FeatherIcon icon="MailIcon" />-->
                <!--                E-Posta Gönder-->
                <!--              </b-button>-->
                <!--            </div>-->
              </div>
            </div>
          </b-card-footer>
        </template>
      </template>
      <template v-else>
        <b-card-footer>
          <div class="mt-1 text-center d-flex justify-content-between">
            <div class="text-left">
              <div class="d-inline">
                <b-button
                  variant="warning"
                  class="mr-2"
                  :to="'/app/proforma-invoices/edit/' + dataItem.id"
                >
                  <FeatherIcon icon="EditIcon" />
                  Güncelle
                </b-button>
              </div>
            </div>
            <div class="text-right">
              <div class="d-inline">
                <b-button
                  :href="downloadUrl"
                  target="_blank"
                  variant="success"
                >
                  <FeatherIcon icon="PrinterIcon" />
                  Yazdır
                </b-button>
              </div>
              <!--            <div class="d-inline">-->
              <!--              <b-button-->
              <!--                variant="danger"-->
              <!--                class="ml-1"-->
              <!--                @click="toggleMailForm"-->
              <!--              >-->
              <!--                <FeatherIcon icon="MailIcon" />-->
              <!--                E-Posta Gönder-->
              <!--              </b-button>-->
              <!--            </div>-->
            </div>
          </div>
        </b-card-footer>
      </template>
    </b-card>
    <!--    <mail-form-->
    <!--      v-if="emailForm"-->
    <!--    />-->
    <!--    <mail-logs />-->
  </div>
</template>
<script>
import {
  BCard, BCardHeader, BCardFooter, BCardTitle, BButton,
} from 'bootstrap-vue'
import ProformaSummary from '@/views/Admin/Proforma_invoices/View/ProformaSummary.vue'
import Products from '@/views/Admin/Proforma_invoices/View/Products.vue'
// import MailLogs from '@/views/Admin/Proforma_invoices/View/MailLogs.vue'
// import MailForm from '@/views/Admin/Proforma_invoices/View/MailForm.vue'

export default {
  name: 'OffersView',
  components: {
    BCard,
    BCardHeader,
    BCardFooter,
    BCardTitle,
    BButton,
    ProformaSummary,
    Products,
    // MailLogs,
    // MailForm,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      emailForm: false,
      downloadUrl: `${this.$store.state.app.baseURL}/Exports/proforma/pdf?token=${localStorage.getItem('downloadToken')}&id=${this.$route.params.id}`,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['proformaInvoices/dataItem']
    },
  },
  methods: {
    toggleMailForm() {
      this.emailForm = !this.emailForm
    },
  },
}
</script>
